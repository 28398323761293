<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
@font-face {
  font-family: 'LattoRegular';
  src: url(./assets/font/Lato-Reg.ttf);
}

@font-face {
  font-family: 'LattoBold';
  src: url(./assets/font/Lato-Bol.ttf);
}

#app {
  font-family: 'LattoRegular' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'LattoBold' !important;
}

.error-msg {
  color: red;
  font-size:15px;
}
</style>
